import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { StepsFunction } from "../components/Steps";
import {
  ColorlibConnector,
  ColorlibStepIcon,
} from "../components/styled/Colorlib";
import "../components/styled/animation.css";
import { useCalculate } from "../api/calculeApi";
import CurrencyFormat from "react-currency-format";
import { Icon } from "@iconify/react";
import CustomTable from "../components/CustomTable";
import CustomList from "../components/CustomList";
import CustomListTwo from "../components/CustomListTwo";
import CustomDialog from "../components/CustomDialog";
import Dropdown from "../components/CustomForm/Dropdown";
export default function Main() {
  const [formData, setFormData] = useState({
    name: "",
    system: [],
    isolationComb: [],
    isolationPlanchar: [],
  });

  const [isolationData, setIsolationData] = React.useState({
    ITE: 0,
    ITECOUT: 0,
    RAMPANTS: 0,
    RAMPANTSCOUT: 0,
    COMBLES: 0,
    COMBLESCOUT: 0,
    TotalIte: 0,
    TotalRamprants: 0,
    TotalCombles: 0,
  });
  const [pacChauffePoele, setPacChauffePoele] = React.useState({
    pac: 1,
    pacCout: 0,
    chauffe: 1,
    chauffeCout: 0,
    poeleGrannule: 1,
    poeleGrannuleCout: 0,
    totalPac: 0,
    totalRampants: 0,
    TotalCombles: 0,
  });
  const [selectB, setSelectB] = React.useState({
    id: 1,
    title: "",
  });
  const [selectedOptionDropMain, setSelectedOptionDropMain] = React.useState({
    id: 1,
    title: "Précaire",
  });
  const [selectedOptionDropD, setSelectedOptionDropD] = React.useState({
    code: 1000,
    name: "Ain",
  });

  const [selectedOptionMiton, setSelectedOptionMiton] = React.useState({
    id: 1,
    title: "non",
  });
  const [selectedOptionMitonTypeFen, setSelectedOptionMitonTypeFen] =
    React.useState({
      id: 1,
      title: "Simple vitrage",
    });
  const [valorisation, setValorisation] = React.useState(6.5);
  const [customFormData, setcustomFormData] = React.useState({
    nom: "",
    prenom: "",
    surface: "",
  });

  const [selectedOptionIsolationMur, setSelectedOptionIsolationMur] =
    React.useState({ id: 1, title: "Aucun" });

  const [selectedOptionIsolationPlan, setSelectedOptionIsolationPlan] =
    React.useState({ id: 1, title: "Aucun" });

  const [selectedOptionIsolationCom, setSelectedOptionIsolationCom] =
    React.useState({ id: 1, title: "Aucun" });
  const [selectedOptionIChaufageCom, setSelectedOptionIChaufageCom] =
    React.useState({
      id: 1,
      title: "Fioul",
    });

  const [activeStep, setActiveStep] = React.useState(0);
  const [result, setResult] = React.useState(null);
  const [TAUXENR, setTAUXENR] = React.useState({
    id: 1,
    title: "Sup. à 50",
  });
  const [recapSteps, setRecapSteps] = React.useState([]);
  const [skipped, setSkipped] = React.useState(new Set());
  const [selectedData, setSelectedData] = useState({
    category: "",
    mode: "",
    surface: "",
    tva: "",
  });

  const [category, setCategory] = useState([
    {
      id: "précaire",
      name: "précaire",
      field: "button",
    },
    {
      id: "précaire1",
      name: "précaire1",
      field: "button",
    },
    {
      id: "précaire2",
      name: "précaire2",
      field: "button",
    },
    {
      id: "précaire3",
      name: "précaire3",
      field: "input",
    },
  ]);
  const [mode, setMode] = useState([{}]);
  const [surface, setSurface] = useState([{}]);
  const [tva, setTva] = useState([{}]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 700);
    window.addEventListener("resize", handleResize);
    setSelectB({
      id: 1,
      title: result?.b1,
    });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [result]);

  const handleSelectedData = (name, value) => {
    setSelectedData({ ...selectedData, [name]: value });
    if (name !== "tva") setRecapSteps([...recapSteps, value]);
  };

  const handleCategory = (name, value) => {
    setCategory({ ...category, [name]: value });
  };

  const handleMode = (name, value) => {
    setMode({ ...mode, [name]: value });
  };

  const handleSurface = (name, value) => {
    setSurface({ ...surface, [name]: value });
  };

  const handleTva = (name, value) => {
    setTva({ ...tva, [name]: value });
  };

  // const isStepOptional = (step) => {
  //     return step === 1;
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [inputValue, setInputValue] = useState(130);
  const handleSubmitCefProject = (e) => {
    e.preventDefault();
    //console.log("Form submitted with input value:", inputValue);
  };
  const [inputValueMoblie, setInputValueMoblie] = useState(130);
  const handleSubmitCefProjectMobile = (e) => {
    e.preventDefault();
    //console.log("Form submitted with input value:", inputValue);
  };

  const handleReset = () => {
    setActiveStep(0);
    setResult(null);
    setSelectedData({ category: "", mode: "", surface: "", tva: "" });
    setcustomFormData({
      nom: "",
      prenom: "",
      surface: "",
    });

    setTAUXENR({
      id: 1,
      title: "Sup. à 50",
    });
    setIsolationData({
      ITE: 0,
      ITECOUT: 0,
      RAMPANTS: 0,
      RAMPANTSCOUT: 0,
      COMBLES: 0,
      COMBLESCOUT: 0,
      TotalIte: 0,
      TotalRamprants: 0,
      TotalCombles: 0,
    });
    setPacChauffePoele({
      pac: 1,
      pacCout: 0,
      chauffe: 1,
      chauffeCout: 0,
      poeleGrannule: 1,
      poeleGrannuleCout: 0,
      totalPac: 0,
      totalRampants: 0,
      TotalCombles: 0,
    });
    setSelectedOptionDropD({
      code: 1000,
      name: "Ain",
    });
    setSelectedOptionDropMain({
      id: 1,
      title: "Précaire",
    });
    setSelectedOptionIChaufageCom({
      id: 1,
      title: "Fioul",
    });
    setSelectedOptionIsolationCom({ id: 1, title: "Aucun" });
    setSelectedOptionIsolationMur({ id: 1, title: "Aucun" });
    setSelectedOptionIsolationPlan({ id: 1, title: "Aucun" });
    setSelectedOptionMiton({
      id: 1,
      title: "non",
    });
    setSelectedOptionMitonTypeFen({
      id: 1,
      title: "Simple vitrage",
    });
    setCompleted(0);
    localStorage.clear();
  };
  const steps = [
    {
      key: "Détails",
      label: "Détails",
    },
    {
      key: "category",
      label: "Détails",
    },
    {
      key: "mode",
      label: "Détails",
    },
    // {
    //   key: "mode",
    //   label: "Détails",
    // },
    // {
    //   key: "mode",
    //   label: "Détails",
    // },
    // {
    //   key: "Total",
    //   label: "Total",
    // },
    // {
    //   key: "tva",
    //   label: "Saisir le TV",
    // },
  ];
  // const steps = ['Choisir une catégorie', 'Choisir un mode de chauffage', 'Choisir une surface', 'Saisir le TV'];
  const useCalculateMutation = useCalculate();
  const [completed, setCompleted] = useState(0);
  // const [cefProget, setCefProget] = useState(110);
  // const [cefProgetTwo, setCefProgetTwo] = useState(43);

  const handleSubmit = async (e) => {
    const payloadData = new FormData();
    payloadData.append("details", JSON.stringify(customFormData));
    payloadData.append("code_postal", selectedOptionDropD.code);
    payloadData.append("precaire", selectedOptionDropMain.id);
    payloadData.append("isolation_chaufage", selectedOptionIChaufageCom.id);
    payloadData.append("isolation_com", selectedOptionIsolationCom.id);
    payloadData.append("isolation_mur", selectedOptionIsolationMur.id);
    payloadData.append("isolation_plan", selectedOptionIsolationPlan.id);
    payloadData.append("miton", selectedOptionMiton.id);
    payloadData.append("miton_fen", selectedOptionMitonTypeFen.id);
    payloadData.append("valo", valorisation);
    payloadData.append("cef_projet", isMobile ? inputValueMoblie : inputValue);
    payloadData.append("isolation_data", JSON.stringify(isolationData));
    payloadData.append("chauffage_data", JSON.stringify(pacChauffePoele));
    payloadData.append("TAUXENR", TAUXENR.id);
    try {
      const res = await useCalculateMutation.mutateAsync(payloadData);
      //console.log(res);
      setResult(res);
      setCompleted(1);
      setInputValue(res.cef_projet1);
      setInputValueMoblie(res.cef_projet1);
      handleNext();
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <div
      style={{ marginBottom: "10px", backgroundColor: "rgb(249 250 251)" }}
      className={"pt-8 pl-2 flex-1 overflow-y-auto "}
      // height: '75%'
    >
      <Stepper
        orientation={isMobile ? "vertical" : "horizontal"}
        alternativeLabel={!isMobile}
        activeStep={activeStep}
        connector={isMobile ? <></> : <ColorlibConnector />}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return !completed ? (
            <Step key={index} {...stepProps}>
              <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
                {/* {selectedData[label.key] ? (
                  <strong>{selectedData[label.key].toUpperCase()}</strong>
                ) : ( */}
                <strong>{label.label.toUpperCase()}</strong>
                {/* )} */}
              </StepLabel>
              {isMobile && (
                <StepContent>
                  <React.Fragment>
                    <StepsFunction
                      TAUXENR={TAUXENR}
                      setTAUXENR={setTAUXENR}
                      handleNext={handleNext}
                      handleSelectedData={handleSelectedData}
                      selectedData={selectedData}
                      index={activeStep}
                      formData={formData}
                      setFormData={setFormData}
                      selectedOptionDropMain={selectedOptionDropMain}
                      setSelectedOptionDropMain={setSelectedOptionDropMain}
                      selectedOptionDropD={selectedOptionDropD}
                      setSelectedOptionDropD={setSelectedOptionDropD}
                      selectedOptionMiton={selectedOptionMiton}
                      setSelectedOptionMiton={setSelectedOptionMiton}
                      selectedOptionMitonTypeFen={selectedOptionMitonTypeFen}
                      setSelectedOptionMitonTypeFen={
                        setSelectedOptionMitonTypeFen
                      }
                      customFormData={customFormData}
                      setcustomFormData={setcustomFormData}
                      selectedOptionIsolationMur={selectedOptionIsolationMur}
                      setSelectedOptionIsolationMur={
                        setSelectedOptionIsolationMur
                      }
                      selectedOptionIsolationPlan={selectedOptionIsolationPlan}
                      setSelectedOptionIsolationPlan={
                        setSelectedOptionIsolationPlan
                      }
                      selectedOptionIsolationCom={selectedOptionIsolationCom}
                      setSelectedOptionIsolationCom={
                        setSelectedOptionIsolationCom
                      }
                      selectedOptionIChaufageCom={selectedOptionIChaufageCom}
                      setSelectedOptionIChaufageCom={
                        setSelectedOptionIChaufageCom
                      }
                      valorisation={valorisation}
                      setValorisation={setValorisation}
                      isolationData={isolationData}
                      setIsolationData={setIsolationData}
                      pacChauffePoele={pacChauffePoele}
                      setPacChauffePoele={setPacChauffePoele}
                    />

                    <div className={"flex justify-around"}>
                      <Button
                        style={{
                          backgroundColor: "#40BF40",
                          border: "0",
                          color: "white",
                        }}
                        variant={"outlined"}
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Retour
                      </Button>
                      {activeStep !== 2 && (
                        <Button
                          style={{
                            backgroundColor: "#40BF40",
                            border: "0",
                            color: "white",
                          }}
                          variant={"outlined"}
                          color="inherit"
                          disabled={activeStep === 2}
                          onClick={handleNext}
                          sx={{ mr: 1 }}
                        >
                          Suivante
                        </Button>
                      )}

                      {/* {activeStep === 4 && (
                        <Button
                          // disabled={!selectedData}
                          style={
                            // selectedData
                            // ? {
                            {
                              color: "white",
                              backgroundImage:
                                "linear-gradient( 136deg, #32475e 0%, #32475e 50%, #40BF40 100%)",
                            }
                            // : {}
                          }
                          onClick={handleSubmit}
                        >
                          {useCalculateMutation.isLoading && (
                            <CircularProgress
                              sx={{ marginRight: "10px" }}
                              size={15}
                            />
                          )}
                          {activeStep === 4 ? "Terminer" : ""}
                        </Button>
                      )} */}
                      {activeStep === 2 && (
                        <Button
                          // disabled={!selectedData}
                          style={
                            // selectedData
                            // ? {
                            {
                              color: "white",
                              backgroundImage:
                                "linear-gradient( 136deg, #32475e 0%, #32475e 50%, #40BF40 100%)",
                            }
                            // : {}
                          }
                          onClick={handleSubmit}
                        >
                          {useCalculateMutation.isLoading && (
                            <CircularProgress
                              sx={{ marginRight: "10px" }}
                              size={15}
                            />
                          )}
                          {activeStep === 2 ? "Terminer" : ""}
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                </StepContent>
              )}
            </Step>
          ) : (
            <></>
          );
        })}
      </Stepper>
      {activeStep > 2 ? (
        <div className={"flex items-center flex-col"}>
          {result && (
            <>
              <div class="flex flex-col mt-8">
                <CustomDialog
                  code={selectedOptionDropD.code}
                  customFormData={customFormData}
                  precaire={selectedOptionDropMain}
                  chauffage={selectedOptionIChaufageCom}
                  result={result}
                  combles={selectedOptionIsolationCom}
                  planchar={selectedOptionIsolationPlan}
                  murs={selectedOptionIsolationMur}
                  fenetre={selectedOptionMitonTypeFen}
                  mitoyenne={selectedOptionMiton}
                  valorisation={valorisation}
                  isolationData={isolationData}
                  setIsolationData={setIsolationData}
                  pacChauffePoele={pacChauffePoele}
                  setPacChauffePoele={setPacChauffePoele}
                />

                <div class="mt-4 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                    {!isMobile ? (
                      <CustomTable
                        selectedOptionIChaufageCom={selectedOptionIChaufageCom}
                        TAUXENR={TAUXENR}
                        setTAUXENR={setTAUXENR}
                        selectB={selectB}
                        setSelectB={setSelectB}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        handleSubmitCefProject={handleSubmitCefProject}
                        result={result}
                        isolationData={isolationData}
                        pacChauffePoele={pacChauffePoele}
                      />
                    ) : (
                      <>
                        <CustomList
                          selectedOptionIChaufageCom={
                            selectedOptionIChaufageCom
                          }
                          TAUXENR={TAUXENR}
                          setTAUXENR={setTAUXENR}
                          selectB={selectB}
                          setSelectB={setSelectB}
                          inputValueMoblie={inputValueMoblie}
                          setInputValueMoblie={setInputValueMoblie}
                          handleSubmitCefProjectMobile={
                            handleSubmitCefProjectMobile
                          }
                          result={result}
                          isolationData={isolationData}
                          pacChauffePoele={pacChauffePoele}
                        />
                        <div>
                          <br></br>
                        </div>
                        {/* <CustomListTwo
                          inputValueMoblie={inputValueMoblie}
                          setInputValueMoblie={setInputValueMoblie}
                          handleSubmitCefProjectMobile={
                            handleSubmitCefProjectMobile
                          }
                          isolationData={isolationData}
                          result={result}
                          pacChauffePoele={pacChauffePoele}
                        /> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            className={"mt-6"}
            style={{
              display: "flex",
            }}
          >
            <div className="mr-2">
              <Button
                startIcon={<Icon icon="system-uicons:reset-forward" />}
                variant={"outlined"}
                color="inherit"
                onClick={handleReset}
              >
                Actualiser
              </Button>
            </div>
          </div>
        </div>
      ) : !isMobile ? (
        <React.Fragment>
          <StepsFunction
            TAUXENR={TAUXENR}
            setTAUXENR={setTAUXENR}
            handleNext={handleNext}
            handleSelectedData={handleSelectedData}
            selectedData={selectedData}
            index={activeStep}
            category={category}
            mode={mode}
            surface={surface}
            tva={tva}
            handleCategory={handleCategory}
            handleMode={handleMode}
            handleSurface={handleSurface}
            handleTva={handleTva}
            // setFormData={(value) => //console.log(value)}
            formData={formData}
            setFormData={setFormData}
            selectedOptionDropMain={selectedOptionDropMain}
            setSelectedOptionDropMain={setSelectedOptionDropMain}
            selectedOptionDropD={selectedOptionDropD}
            setSelectedOptionDropD={setSelectedOptionDropD}
            selectedOptionMiton={selectedOptionMiton}
            setSelectedOptionMiton={setSelectedOptionMiton}
            selectedOptionMitonTypeFen={selectedOptionMitonTypeFen}
            setSelectedOptionMitonTypeFen={setSelectedOptionMitonTypeFen}
            customFormData={customFormData}
            setcustomFormData={setcustomFormData}
            selectedOptionIsolationMur={selectedOptionIsolationMur}
            setSelectedOptionIsolationMur={setSelectedOptionIsolationMur}
            selectedOptionIsolationPlan={selectedOptionIsolationPlan}
            setSelectedOptionIsolationPlan={setSelectedOptionIsolationPlan}
            selectedOptionIsolationCom={selectedOptionIsolationCom}
            setSelectedOptionIsolationCom={setSelectedOptionIsolationCom}
            selectedOptionIChaufageCom={selectedOptionIChaufageCom}
            setSelectedOptionIChaufageCom={setSelectedOptionIChaufageCom}
            valorisation={valorisation}
            setValorisation={setValorisation}
            isolationData={isolationData}
            setIsolationData={setIsolationData}
            pacChauffePoele={pacChauffePoele}
            setPacChauffePoele={setPacChauffePoele}
          />

          <div className={"flex justify-around"}>
            <Button
              style={{
                backgroundColor: "#40BF40",
                border: "0",
                color: "white",
              }}
              variant={"outlined"}
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Retour
            </Button>
            {activeStep !== 2 && (
              <Button
                style={{
                  backgroundColor: "#40BF40",
                  border: "0",
                  color: "white",
                }}
                variant={"outlined"}
                color="inherit"
                disabled={activeStep === 2}
                onClick={handleNext}
                sx={{ mr: 1 }}
              >
                Suivante
              </Button>
            )}
            {/* {activeStep !== 4 && (
              <Button
                style={{
                  backgroundColor: "#40BF40",
                  border: "0",
                  color: "white",
                }}
                variant={"outlined"}
                color="inherit"
                disabled={activeStep === 4}
                onClick={handleNext}
                sx={{ mr: 1 }}
              >
                Suivante
              </Button>
            )} */}
            {/*<Box sx={{flex: '1 1 auto'}}/>*/}
            {/* {activeStep === 3 && (
              <Button
                // disabled={!selectedData.tva}
                style={{
                  color: "white",
                  backgroundImage:
                    "linear-gradient( 136deg, #104110 0%, #a5c289 50%, rgb(64, 191, 64)  100%)",
                }}
                onClick={handleSubmit}
              >
                {useCalculateMutation.isLoading && (
                  <CircularProgress sx={{ marginRight: "10px" }} size={15} />
                )}
                {activeStep === 4 ? "Terminer" : ""}
              </Button>
            )} */}
            {activeStep === 2 && (
              <Button
                // disabled={!selectedData.tva}
                style={{
                  color: "white",
                  backgroundImage:
                    "linear-gradient( 136deg, #104110 0%, #a5c289 50%, rgb(64, 191, 64)  100%)",
                }}
                onClick={handleSubmit}
              >
                {useCalculateMutation.isLoading && (
                  <CircularProgress sx={{ marginRight: "10px" }} size={15} />
                )}
                {activeStep === 2 ? "Terminer" : ""}
              </Button>
            )}
          </div>
        </React.Fragment>
      ) : (
        ""
      )}

      {/*<RecapSteps steps={selectedData}></RecapSteps>*/}
    </div>
  );
}
