import React, { useEffect, useState } from "react";
import {
  useChaufage,
  useIsolationComble,
  useIsolationMur,
  useIsolationPlanchar,
} from "../api/calculeApi";
// import Dropdown from "./Dropdown";

export default function IsolationForm({ setIsolationData, isolationData }) {
  // const [formData, setFormData] = useState({
  //   system: [],
  //   isolationComb: [],
  //   isolationPlanchar: [],
  // });
  const [completed, setCompleted] = React.useState(0);

  const isolationMur = useIsolationMur();
  const chaufage = useChaufage();
  const isolationPlanchar = useIsolationPlanchar();
  const isolationComble = useIsolationComble();
  const isolationMurData = isolationMur?.data;
  const isolationPlancharData = isolationPlanchar?.data;
  const isolationCombleData = isolationComble?.data;

  const handleChange = (e) => {
    setIsolationData({
      ...isolationData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <form className="max-w-md mx-auto">
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 text-center md:w md:mb-0">
          <label className="block mb-5 font-bold text-gray-700" htmlFor="Nom">
            ITE
          </label>
          <div className="flex justify-around">
            <input
              style={{ borderColor: "#a8d13d4d" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="ITE"
              name="ITE"
              type="text"
              placeholder="m²"
              onChange={(e) => handleChange(e)}
            />
            <input
              style={{ borderColor: "#a8d13d4d" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="ITECOUT"
              name="ITECOUT"
              type="text"
              placeholder="P.U €"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 text-center md:w md:mb-0">
          <label className="block mb-5 font-bold text-gray-700" htmlFor="Nom">
            RAMPANTS
          </label>
          <div className="flex justify-around">
            <input
              style={{ borderColor: "#a8d13d4d" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="RAMPANTS"
              name="RAMPANTS"
              type="text"
              placeholder="m²"
              onChange={(e) => handleChange(e)}
            />
            <input
              style={{ borderColor: "#a8d13d4d" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="RAMPANTSCOUT"
              name="RAMPANTSCOUT"
              type="text"
              placeholder="P.U €"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 text-center md:w md:mb-0">
          <label className="block mb-5 font-bold text-gray-700" htmlFor="Nom">
            COMBLES
          </label>
          <div className="flex justify-around">
            <input
              style={{ borderColor: "#a8d13d4d" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="COMBLES"
              name="COMBLES"
              type="text"
              placeholder="m²"
              onChange={(e) => handleChange(e)}
            />
            <input
              style={{ borderColor: "#a8d13d4d" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="COMBLESCOUT"
              name="COMBLESCOUT"
              type="text"
              placeholder="P.U €"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
