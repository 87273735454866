import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./pages/Main";

function App() {
  return (
    <div className="flex flex-col h-screen bg-gray-100 ">
      <Header />
      <Main></Main>
      <Footer />
    </div>
  );
}

export default App;
