import React, { useEffect, useState } from "react";
import {
  useChaufage,
  useIsolationComble,
  useIsolationMur,
  useIsolationPlanchar,
} from "../api/calculeApi";
// import Dropdown from "./Dropdown";

export default function ChauffageForm({ pacChauffePoele, setPacChauffePoele }) {
  // const [formData, setFormData] = useState({
  //   system: [],
  //   isolationComb: [],
  //   isolationPlanchar: [],
  // });
  const [completed, setCompleted] = React.useState(0);

  const isolationMur = useIsolationMur();
  const chaufage = useChaufage();
  const isolationPlanchar = useIsolationPlanchar();
  const isolationComble = useIsolationComble();
  const isolationMurData = isolationMur?.data;
  const isolationPlancharData = isolationPlanchar?.data;
  const isolationCombleData = isolationComble?.data;

  const handleChange = (e) => {
    setPacChauffePoele({
      ...pacChauffePoele,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <form className="max-w-md mx-auto">
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 text-center md:w md:mb-0">
          <label className="block mb-5 font-bold text-gray-700" htmlFor="Nom">
            PAC AIR/EAU OU AIR/AIR
          </label>
          <div className="flex justify-around">
            <input
              style={{ borderColor: "#40BF40" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="pac"
              name="pac"
              type="text"
              value={pacChauffePoele.pac}
              placeholder="QT"
              onChange={(e) => handleChange(e)}
            />
            <input
              style={{ borderColor: "#40BF40" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="pacCout"
              name="pacCout"
              type="text"
              placeholder="P.U €"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 text-center md:w md:mb-0">
          <label className="block mb-5 font-bold text-gray-700" htmlFor="Nom">
            CHAUFFE-EAU THERMODYNAMIQUE
          </label>
          <div className="flex justify-around">
            <input
              style={{ borderColor: "#40BF40" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="chauffe"
              value={pacChauffePoele.chauffe}
              name="chauffe"
              type="text"
              placeholder="QT"
              onChange={(e) => handleChange(e)}
            />
            <input
              style={{ borderColor: "#40BF40" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="chauffeCout"
              name="chauffeCout"
              type="text"
              placeholder="P.U €"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mb-6 -mx-3">
        <div className="w-full px-3 mb-6 text-center md:w md:mb-0">
          <label className="block mb-5 font-bold text-gray-700" htmlFor="Nom">
            POELE A GRANULÉS
          </label>
          <div className="flex justify-around">
            <input
              style={{ borderColor: "#40BF40" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="poeleGrannule"
              name="poeleGrannule"
              value={pacChauffePoele.poeleGrannule}
              type="text"
              placeholder="QT"
              onChange={(e) => handleChange(e)}
            />
            <input
              style={{ borderColor: "#40BF40" }}
              className="w-1/3 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="poeleGrannuleCout"
              name="poeleGrannuleCout"
              type="text"
              placeholder="P.U €"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
