import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchDropdown from "./SearchDropdown";
import Dropdown from "./Dropdown.jsx";
import { useChaufage, useCodePostal, usePrecarite } from "../../api/calculeApi";

export default function CustomForm({
  selectedOptionDropMain,
  setSelectedOptionDropMain,
  selectedOptionDropD,
  setSelectedOptionDropD,
  customFormData,
  setcustomFormData,
  selectedOptionIChaufageCom,
  setSelectedOptionIChaufageCom,
  valorisation,
  setValorisation,
  TAUXENR,
  setTAUXENR,
}) {
  const options = [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
    "Option 5",
    "Option 6",
    "Option 7",
    "Option 8",
  ];
  const chaufage = useChaufage();
  const chaufageData = chaufage?.data;
  // const [customFormData, setcustomFormData] = React.useState({
  //   nom: "",
  //   prenom: "",
  //   surface: "",
  // });
  const [completed, setCompleted] = React.useState(0);
  const handleChange = (e) => {
    setcustomFormData({
      ...customFormData,
      [e.target.name]: e.target.value,
    });
  };
  const codePostal = useCodePostal();
  const precarite = usePrecarite();
  const codePostalData = codePostal?.data;
  const precariteData = precarite?.data;

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("etape_1", JSON.stringify(customFormData));
    localStorage.setItem("code_postal", JSON.stringify(selectedOptionDropD));
    localStorage.setItem("precarite", JSON.stringify(selectedOptionDropMain));
    setCompleted(1);
  };
  // const [selectedOptionDropMain, setSelectedOptionDropMain] = React.useState(
  //   {}
  // );
  // const [selectedOptionDropD, setSelectedOptionDropD] = React.useState({});

  return (
    <div className="w-full max-w-md mx-auto">
      <form className="px-8 pt-6 pb-2 mb-4" onSubmit={handleSubmit}>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 mb-6 md:w md:mb-0">
            <label className="block mb-2 font-bold text-gray-700" htmlFor="Nom">
              Nom et Prenom
            </label>
            <input
              style={{ borderColor: "#a8d13d4d" }}
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="nom"
              name="nom"
              type="text"
              placeholder="Entrez votre nom et pernom"
              onChange={(e) => handleChange(e)}
            />
          </div>
          {/* <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Prenom"
            >
              Prénom
            </label>
            <input
              style={{ borderColor: "#a8d13d4d" }}
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="prenom"
              name="prenom"
              type="text"
              placeholder="Entrez votre prenom"
              onChange={(e) => handleChange(e)}
            />
          </div> */}
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Surface"
            >
              Surface habitable
            </label>
            <input
              style={{ borderColor: "#a8d13d4d" }}
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="surface"
              name="surface"
              min={0}
              type="number"
              placeholder="Entrez surface"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="CodePostal"
            >
              Code Postal
            </label>
            {/* <input
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="codepostal"
              type="number"
              placeholder="Entrez Code Postal"
            /> */}
            {codePostal.isSuccess && (
              <SearchDropdown
                selectedOptionDropD={selectedOptionDropD}
                setSelectedOptionDropD={setSelectedOptionDropD}
                options={options}
                codePostalData={codePostalData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 mb-6 md:w md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="precarite"
            >
              TAUX ENR
            </label>
            {/* <input
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="precarite"
              type="text"
              placeholder="Entrez Précarité"
            /> */}
            {precarite.isSuccess && (
              <Dropdown
                selectedOptionDropMain={TAUXENR}
                setSelectedOptionDropMain={setTAUXENR}
                options={options}
                precariteData={[
                  {
                    id: 1,
                    title: "Sup. à 50",
                  },
                  {
                    id: 2,
                    title: "Inf. à 50",
                  },
                ]}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
