import React from "react";
import CurrencyFormat from "react-currency-format";

export default function CustomTable({
  result,
  inputValue,
  setInputValue,
  handleSubmitCefProject,
  isolationData,
  pacChauffePoele,
  selectB,
  setSelectB,
  selectedOptionIChaufageCom,
}) {
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <div style={{ maxWidth: "800px" }}>
      <div className="flex justify-around"></div>
      <br></br>
      <div className="flex justify-around">
        <strong>Simulateur Reno globale BAR TH 145</strong>
      </div>
      <br></br>
      <div className="flex-col justify-around ml-6 mr-6">
        <div className="flex justify-around mb-1">
          {/* <span>Scénario BBC</span> */}
        </div>
        <table className="w-full">
          <thead class="bg-gray-50">
            <tr>
              <th
                style={{
                  backgroundColor: "#40BF40",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                Prime
              </th>
              <th
                style={{
                  backgroundColor: "#40BF40",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                Cef Initiale
              </th>
              <th
                style={{
                  backgroundColor: "#40BF40",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                Cef Projet
              </th>
              {/* <th
                style={{
                  backgroundColor: "#40BF40",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                CEP projet
              </th> */}
              <th
                style={{
                  backgroundColor: "#40BF40",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                Shab
              </th>
              <th
                style={{
                  backgroundColor: "#40BF40",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                B
              </th>
              <th
                style={{
                  backgroundColor: "#40BF40",
                  color: "white",
                }}
                class="px-6 py-2 text-xs text-gray-500"
              >
                CUMAC
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {selectedOptionIChaufageCom.id !== 1 && (
              <>
                <tr className="whitespace-nowrap">
                  <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                    <CurrencyFormat
                      value={result?.amount1}
                      thousandSeparator={true}
                      prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                    <CurrencyFormat
                      value={result?.cef_initial}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                    <CurrencyFormat
                      value={result?.cef_projet1}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  {/* <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.cep_projet1}
                  thousandSeparator={true}
                  // prefix={"€"}
                  displayType={"text"}
                />
              </td> */}
                  <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                    <CurrencyFormat
                      value={result?.shab}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                    <CurrencyFormat
                      value={result?.b1}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                    <CurrencyFormat
                      value={result?.cumac1}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                </tr>
                <tr className="whitespace-nowrap">
                  <td
                    colSpan="7"
                    className="px-4 py-3 text-sm text-center text-gray-500 border"
                  >
                    <span>
                      AU MOINS 1 geste d’isolation + BT collectifs + PAC OU VRV
                    </span>
                  </td>
                </tr>
              </>
            )}
            {selectedOptionIChaufageCom.id === 1 && (
              <>
                <tr className="whitespace-nowrap">
                  <td
                    className={`text-center py-3 px-4 border  text-sm text-gray-500`}
                  >
                    <CurrencyFormat
                      value={result?.amount2}
                      thousandSeparator={true}
                      prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  <td
                    className={`text-center py-3 px-4 border  text-sm text-gray-500`}
                  >
                    <CurrencyFormat
                      value={result?.cef_initial}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  <td
                    className={`text-center py-3 px-4 border  text-sm text-gray-500`}
                  >
                    <CurrencyFormat
                      value={result?.cef_projet2}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  {/* <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                <CurrencyFormat
                  value={result?.cep_projet2}
                  thousandSeparator={true}
                  // prefix={"€"}
                  displayType={"text"}
                />
              </td> */}
                  <td
                    className={`text-center py-3 px-4 border text-sm text-gray-500`}
                  >
                    <CurrencyFormat
                      value={result?.shab}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                    <CurrencyFormat
                      value={result?.b2}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                    <CurrencyFormat
                      value={result?.cumac2}
                      thousandSeparator={true}
                      // prefix={"€"}
                      displayType={"text"}
                    />
                  </td>
                </tr>
                <tr className="whitespace-nowrap">
                  <td
                    colSpan="7"
                    className="px-4 py-3 text-sm text-center text-gray-500 border"
                  >
                    <span>
                      AU MOINS 1 geste d’isolation + BT collectifs + PAC OU VRV
                    </span>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <br></br>
      {/* <div className="flex-col justify-around ml-2 mr-2">
        <div className="flex justify-around mb-1">
          <span>COUT TRAVAUX ISOLATION</span>
        </div>
        <div className="flex justify-around">
          <table className="">
            <thead class="bg-gray-50">
              <tr>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  TRAVAUX
                </th>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  P.U
                </th>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  M²
                </th>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  COUT TRAVAUX
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-300">
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong>ITE</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    value={isolationData?.ITECOUT}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  {isolationData?.ITE + " m²"}
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    value={result?.ITE}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong>RAMPANTS</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    value={isolationData?.RAMPANTSCOUT}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  {isolationData?.RAMPANTS + " m²"}
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    value={result?.RAMPANTS}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong>COMBLES</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    value={isolationData?.COMBLESCOUT}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  {isolationData?.COMBLES + " m²"}
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    value={result?.COMBLES}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br></br>
      <div className="flex-col justify-around ml-2 mr-2">
        <div className="flex justify-around mb-1">
          <span>COUT TRAVAUX CHAUFFAGE</span>
        </div>
        <div className="flex justify-around">
          <table className="">
            <thead class="bg-gray-50">
              <tr>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  TRAVAUX
                </th>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  PU
                </th>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  QT
                </th>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  COUT TRAVAUX
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-300">
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong> PAC AIR/EAU OU AIR/AIR</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={pacChauffePoele?.pacCout}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  {pacChauffePoele?.pac}
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={result?.pacEauAir}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong> CHAUFFE-EAU THERMODYNAMIQUE</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={pacChauffePoele?.chauffeCout}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  {pacChauffePoele?.chauffe}
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={result?.chaufEauTHermodynamique}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong> POELE A GRANULÉS</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={pacChauffePoele?.poeleGrannuleCout}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  {pacChauffePoele?.poeleGrannule}
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={result?.PoeleAGrannule}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br></br>
      <div className="flex-col justify-around ml-2 mr-2">
        <div className="flex justify-around mb-1">
          <span>COUT Scénario</span>
        </div>
        <div className="flex justify-around">
          <table className="w-full ml-5 mr-5">
            <thead class="bg-gray-50">
              <tr>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  SCENARIO
                </th>
                <th
                  style={{
                    backgroundColor: "#40BF40",
                    color: "white",
                  }}
                  class="px-6 py-2 text-xs text-gray-500"
                >
                  COUT TRAVAUX
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-300">
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong>ITE + PAC + BALLON</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={result?.itePacBaloon?.cout}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong>ITE + PAC + BALLON + POELE</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={result?.itePacBaloonPoele?.cout}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong>RAMPANTS + PAC + BALLON + POELE</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={result?.ramponPacBaloonPoele?.cout}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <strong>COMBLES + PAC + BALLON + POELE</strong>
                </td>
                <td className="px-6 py-4 text-sm text-center text-gray-500 border">
                  <CurrencyFormat
                    prefix={"€"}
                    value={result?.comblesPacBaloonPoele?.cout}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
}
