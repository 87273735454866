import React, { useEffect, useState } from "react";
import {
  useChaufage,
  useIsolationComble,
  useIsolationMur,
  useIsolationPlanchar,
} from "../../api/calculeApi";
import Dropdown from "./Dropdown";

export default function CategoryForm({
  selectedOptionIsolationMur,
  setSelectedOptionIsolationMur,
  selectedOptionIsolationPlan,
  setSelectedOptionIsolationPlan,
  selectedOptionIsolationCom,
  setSelectedOptionIsolationCom,
  selectedOptionIChaufageCom,
  setSelectedOptionIChaufageCom,
}) {
  // const [formData, setFormData] = useState({
  //   system: [],
  //   isolationComb: [],
  //   isolationPlanchar: [],
  // });
  const [completed, setCompleted] = React.useState(0);

  const isolationMur = useIsolationMur();
  const chaufage = useChaufage();
  const isolationPlanchar = useIsolationPlanchar();
  const isolationComble = useIsolationComble();
  const isolationMurData = isolationMur?.data;
  const isolationPlancharData = isolationPlanchar?.data;
  const isolationCombleData = isolationComble?.data;
  const chaufageData = chaufage?.data;

  // const [selectedOptionIsolationMur, setSelectedOptionIsolationMur] =
  //   React.useState({});

  // const [selectedOptionIsolationPlan, setSelectedOptionIsolationPlan] =
  //   React.useState({});

  // const [selectedOptionIsolationCom, setSelectedOptionIsolationCom] =
  //   React.useState({});
  // const [selectedOptionIChaufageCom, setSelectedOptionIChaufageCom] =
  //   React.useState({});

  return (
    <form className="max-w-md mx-auto">
      <div className="mb-4">
        <label className="block mb-2 font-bold text-gray-700">
          Système de chauffage :
        </label>
        <div>
          {chaufage.isSuccess && (
            <Dropdown
              selectedOptionDropMain={selectedOptionIChaufageCom}
              setSelectedOptionDropMain={setSelectedOptionIChaufageCom}
              precariteData={chaufageData}
            />
          )}
        </div>
      </div>

      <div className="mb-4">
        <label className="block mb-2 font-bold text-gray-700">
          Isolation des Murs
        </label>
        <div>
          {isolationMur.isSuccess && (
            <Dropdown
              selectedOptionDropMain={selectedOptionIsolationMur}
              setSelectedOptionDropMain={setSelectedOptionIsolationMur}
              precariteData={isolationMurData}
            />
          )}
        </div>
      </div>

      <div className="mb-4">
        <label className="block mb-2 font-bold text-gray-700">
          Isolation des planchers bas
        </label>
        <div>
          {isolationPlanchar.isSuccess && (
            <Dropdown
              selectedOptionDropMain={selectedOptionIsolationPlan}
              setSelectedOptionDropMain={setSelectedOptionIsolationPlan}
              precariteData={isolationPlancharData}
            />
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="block mb-2 font-bold text-gray-700">
          Isolation des combles
        </label>
        <div>
          {isolationComble.isSuccess && (
            <Dropdown
              selectedOptionDropMain={selectedOptionIsolationCom}
              setSelectedOptionDropMain={setSelectedOptionIsolationCom}
              precariteData={isolationCombleData}
            />
          )}
        </div>
      </div>
    </form>
  );
}
