import React from "react";
import CurrencyFormat from "react-currency-format";

export default function CustomList({
  result,
  inputValueMoblie,
  setInputValueMoblie,
  handleSubmitCefProjectMobile,
  selectB,
  TAUXENR,
  selectedOptionIChaufageCom,
}) {
  const handleChange = (e) => {
    setInputValueMoblie(e.target.value);
  };
  //console.log(selectB);
  return (
    <ul className="bg-gray-50">
      {selectedOptionIChaufageCom.id === 1 && (
        <div
          style={{
            border: "1px solid #40BF40",
            marginTop: "1rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong> Prime</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.amount2}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Cef Initiale</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_initial}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Cef Projet</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_projet2}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          {/* <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> CEP projet</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cep_projet2}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li> */}
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Shab</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.shab}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>B</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.b2}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>CUMAC</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cumac2}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>

          <li className="flex justify-between px-6 py-2 text-xs text-gray-500">
            <span>
              AU MOINS 1 geste d’isolation + BT collectifs + PAC OU VRV
            </span>
          </li>
        </div>
      )}
      {selectedOptionIChaufageCom.id !== 1 && (
        <div
          style={{
            border: "1px solid #40BF40",
          }}
        >
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              {" "}
              <strong> Prime</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.amount1}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Cef Initiale</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_initial}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span
              style={{
                display: "grid",
                alignItems: "center !important",
              }}
            >
              <strong> Cef Projet</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_projet1}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          {/* <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> CEP projet</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cep_projet1}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li> */}
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong> Shab</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.shab}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong> B</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.b1}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>CUMAC</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cumac1}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              AU MOINS 1 geste d’isolation + BT collectifs + PAC OU VRV
            </span>
          </li>
        </div>
      )}
    </ul>
  );
}
