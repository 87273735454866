import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog({
  mitoyenne,
  fenetre,
  murs,
  planchar,
  combles,
  chauffage,
  precaire,
  code,
  result,
  customFormData,
  valorisation,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        style={{
          color: "#40BF40",
          border: "1px solid #40BF40",
          fontWeight: "900",
        }}
        onClick={handleClickOpen}
        className="flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
          />
        </svg>
        {customFormData?.nom}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ backgroundColor: "#40BF40", position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              {/* <CloseIcon /> */}Fermer
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText
              primary="Nom et prénom"
              secondary={customFormData?.nom}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Surface habitable"
              secondary={customFormData?.surface}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="Code postal" secondary={code} />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="Précarité" secondary={precaire?.title} />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Système de chauffage"
              secondary={chauffage?.title}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Isolation des combles"
              secondary={combles?.title}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Isolation des planchar bas"
              secondary={planchar?.title}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Isolation des murs"
              secondary={murs?.title}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Type de fenêtres"
              secondary={fenetre?.title}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="Mitoyenne" secondary={mitoyenne?.title} />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="Valorisation" secondary={valorisation} />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Zone climatique"
              secondary={result?.zone_climatique}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Département"
              secondary={result?.department}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="T°ext de référence"
              secondary={result?.reference}
            />
          </ListItem>
        </List>
      </Dialog>
    </>
  );
}
{
  {
  }
}
