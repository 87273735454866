import React from "react";
import { useMitoyenne, useTypefenetre } from "../../api/calculeApi";
import Dropdown from "./Dropdown";
import SearchDropdown from "./SearchDropdown";

export default function ListChaufForm({
  selectedOptionMiton,
  setSelectedOptionMiton,
  selectedOptionMitonTypeFen,
  setSelectedOptionMitonTypeFen,
  valorisation,
  setValorisation,
}) {
  const [completed, setCompleted] = React.useState(0);

  const mitoyenne = useMitoyenne();
  const typeFene = useTypefenetre();
  const mitoyenneData = mitoyenne?.data;
  const typeFeneData = typeFene?.data;

  // const [selectedOptionMiton, setSelectedOptionMiton] = React.useState({});
  // const [selectedOptionMitonTypeFen, setSelectedOptionMitonTypeFen] =
  React.useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("mitoyenne", JSON.stringify(selectedOptionMiton));
    localStorage.setItem(
      "typeFene",
      JSON.stringify(selectedOptionMitonTypeFen)
    );
    setCompleted(1);
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <form className="px-8 pt-6 pb-2 mb-4" onSubmit={handleSubmit}>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full max-w-md px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="Surface"
            >
              Mitoyenneté
            </label>
            {mitoyenne.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionMiton}
                setSelectedOptionDropMain={setSelectedOptionMiton}
                precariteData={mitoyenneData}
              />
            )}
          </div>
          <div className="w-full max-w-md px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="CodePostal"
            >
              Type de fenêtres
            </label>

            {typeFene.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionMitonTypeFen}
                setSelectedOptionDropMain={setSelectedOptionMitonTypeFen}
                precariteData={typeFeneData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full max-w-md px-3 mb-6 md:w md:mb-0">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="precarite"
            >
              Valorisation
            </label>
            <input
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="precarite"
              type="number"
              value={valorisation}
              onChange={(e) => setValorisation(e.target.value)}
              placeholder="Valorisation ..."
              min={0}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
