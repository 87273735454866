import { Button } from "@mui/material";
import { useEffect } from "react";
import "../components/styled/animation.css";
import ChauffageForm from "./ChauffageForm";
import CategoryForm from "./CustomForm/CategoryForm";
import CustomForm from "./CustomForm/CustomForm";
import ListChaufForm from "./CustomForm/ListChaufForm";
import IsolationForm from "./IsolationForm";

export function StepsFunction({
  index,
  handleSelectedData,
  selectedData,
  isolationData,
  setIsolationData,
  pacChauffePoele,
  setPacChauffePoele,
  formData,
  setFormData,
  selectedOptionDropMain,
  setSelectedOptionDropMain,
  selectedOptionDropD,
  setSelectedOptionDropD,
  selectedOptionMiton,
  setSelectedOptionMiton,
  selectedOptionMitonTypeFen,
  setSelectedOptionMitonTypeFen,
  customFormData,
  setcustomFormData,

  selectedOptionIsolationMur,
  setSelectedOptionIsolationMur,
  selectedOptionIsolationPlan,
  setSelectedOptionIsolationPlan,
  selectedOptionIsolationCom,
  setSelectedOptionIsolationCom,
  selectedOptionIChaufageCom,
  setSelectedOptionIChaufageCom,
  valorisation,
  setValorisation,
  TAUXENR,
  setTAUXENR,
}) {
  const handleChangeData = (e) => {
    e.preventDefault();
    if (e.target.name === "tva")
      handleSelectedData(e.target.name, e.target.value);
    else {
      handleSelectedData(e.target.name, e.target.id);
      // handleNext()
    }
  };
  // useEffect(() => {
  //   //console.log(formData);
  // }, [formData]);

  const conditional = (state, value) => {
    return state === value;
  };
  const handleCategoryForm = (value) => {
    setFormData(value);
  };
  // eslint-disable-next-line default-case
  switch (index) {
    case 0:
      return (
        <div key={index} className={"fadeIn my-10 flex justify-around"}>
          <CustomForm
            TAUXENR={TAUXENR}
            setTAUXENR={setTAUXENR}
            selectedOptionDropMain={selectedOptionDropMain}
            setSelectedOptionDropMain={setSelectedOptionDropMain}
            selectedOptionDropD={selectedOptionDropD}
            setSelectedOptionDropD={setSelectedOptionDropD}
            customFormData={customFormData}
            setcustomFormData={setcustomFormData}
            selectedOptionIChaufageCom={selectedOptionIChaufageCom}
            setSelectedOptionIChaufageCom={setSelectedOptionIChaufageCom}
            valorisation={valorisation}
            setValorisation={setValorisation}
          />
        </div>
      );
    case 1:
      return (
        <div key={index} className={"fadeIn my-10 flex justify-around"}>
          <CategoryForm
            selectedOptionIsolationMur={selectedOptionIsolationMur}
            setSelectedOptionIsolationMur={setSelectedOptionIsolationMur}
            selectedOptionIsolationPlan={selectedOptionIsolationPlan}
            setSelectedOptionIsolationPlan={setSelectedOptionIsolationPlan}
            selectedOptionIsolationCom={selectedOptionIsolationCom}
            setSelectedOptionIsolationCom={setSelectedOptionIsolationCom}
            selectedOptionIChaufageCom={selectedOptionIChaufageCom}
            setSelectedOptionIChaufageCom={setSelectedOptionIChaufageCom}
          />
        </div>
      );
    case 2:
      return (
        <div key={index} className={"fadeIn my-10 flex justify-around"}>
          <ListChaufForm
            selectedOptionMiton={selectedOptionMiton}
            setSelectedOptionMiton={setSelectedOptionMiton}
            selectedOptionMitonTypeFen={selectedOptionMitonTypeFen}
            setSelectedOptionMitonTypeFen={setSelectedOptionMitonTypeFen}
            valorisation={valorisation}
            setValorisation={setValorisation}
          />
        </div>
      );
    case 3:
      return (
        <div key={index} className={"fadeIn my-10 flex justify-around"}>
          <IsolationForm
            isolationData={isolationData}
            setIsolationData={setIsolationData}
          />
        </div>
      );
    case 4:
      return (
        <div key={index} className={"fadeIn my-10 flex justify-around"}>
          <ChauffageForm
            pacChauffePoele={pacChauffePoele}
            setPacChauffePoele={setPacChauffePoele}
          />
        </div>
      );
    // case 5:
    //   return (
    //     <div key={index} className="flex justify-around my-8 fadeIn">
    //       <div className="w-3/5 px-3 m-auto">
    //         <label
    //           className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
    //           htmlFor="grid-password"
    //         >
    //           Taux de valorisation
    //         </label>
    //         <input
    //           onChange={handleChangeData}
    //           name="tva"
    //           type={"number"}
    //           className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none bg-gray-50 focus:outline-none focus:bg-white focus:border-gray-500"
    //           id="grid-tva"
    //           placeholder="3600.."
    //         />
    //       </div>
    //     </div>
    //   );
  }
}
